import { Box, Chip, Grid, Link, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import * as XLSX from "xlsx";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import ResponsiveAppBar from "examples/Navbars/ResponsiveNavbar/ResponsiveNavbar";
import DataTable from "examples/Tables/DataTable";
import page_Routes from "page_routes";
import React, { useEffect, useState } from "react";
import { getActualConsolidatedDemandBatches } from "../../../redux/slices/production";
import { useDispatch, useSelector } from "react-redux";
import HomeIcon from "@mui/icons-material/Home";
import CustomSeparator from "examples/CustomSeprator/customSeprator";
import axios from "axios";
function DashboardProductionActualConsolidatedSheet() {
  const dispatch = useDispatch(); // Redux dispatch function
  const [category, setCategory] = useState("all");

  const [sales_order_last_updated, setSalesOrderLastUpdated] = useState(""); // State to store demand last updated
  const [sales_invoice_last_updated, setSalesInvoiceLastUpdated] = useState(""); // State to store invoice last updated

  useEffect(() => {
    dispatch(getActualConsolidatedDemandBatches(category)); // Call getContainStore1 action
    const getDate = async () => {
      try {
        const res = await axios.get("https://staging-2.trigeminal.ai/date");
        console.log("API response:", res.data);
        setSalesOrderLastUpdated(res.data?.sales_order_last_updated.date);
        setSalesInvoiceLastUpdated(res.data?.sales_invoice_last_updated.date);
      } catch (error) {
        console.error("Error fetching date:", error);
      }
    };

    getDate();
  }, [category]);
  const { production } = useSelector((state) => state.production);
  const breadcrumbs = [
    <Link underline="hover" key="1" color="#FEFFFF" href="/dashboards/default">
      <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" color="#FEFFFF" />
    </Link>,

    <Typography sx={{ fontSize: "13px", fontWeight: 800 }} key="3" color="#FEFFFF">
      Consolidated Batches-SO{" "}
    </Typography>,
  ];
  const columnDefs = [
    {
      headerName: "Sl No",
      field: "sl_no",
      sortable: true,
      filter: true,
      width: "87px",
      pinned: "left",
    },
    {
      headerName: "Product Code",
      field: "product_code",
      sortable: true,
      filter: true,
      pinned: "left",
      width: "135px",
    },
    {
      headerName: "Product Description",
      field: "product_description",
      sortable: true,
      filter: true,
      pinned: "left",
      width: "200px",
    },

    {
      headerName: "Live Demand-SO",
      field: "live_demand",
      sortable: true,
      filter: true,
      width: "165px",
    },
    // {
    //   headerName: "Stock",
    //   field: "stock",
    //   sortable: true,
    //   filter: true,
    //   width: "150px",
    // },
    {
      headerName: "Stock",
      children: [
        { headerName: "GOVT", field: "govt_stock", filter: true, width: "100px" },
        { headerName: "DLR", field: "dlr_stock", filter: true, width: "100px" },
        { headerName: "OTGT", field: "otgt_stock", filter: true, width: "100px" },
        { headerName: "Total", field: "stock", filter: true, width: "100px" },
      ],
    },
    {
      headerName: "MTS Pending",
      field: "total_mts",
      sortable: true,
      filter: true,
      width: "160px",
    },
    {
      headerName: "Net Demand-SO",
      field: "net_demand",
      sortable: true,
      filter: true,
      width: "160px",
    },
    {
      headerName: "Budget",
      field: "total_budget",
      sortable: true,
      filter: true,
      width: "150px",
    },
    {
      headerName: "Quantity to Produce(ml/gm/ea)",
      field: "quantity_to_produce",
      sortable: true,
      filter: true,
      width: "220px",
    },
    {
      headerName: "Unit",
      field: "unit",
      sortable: true,
      filter: true,

      width: "100px",
    },

    {
      headerName: "Batches to Produce",
      field: "batches_to_produce",
      sortable: true,
      filter: true,
      width: "200px",
    },
    // {
    //   headerName: "Demand Value(INR)",
    //   field: "demand_price",
    //   sortable: true,
    //   filter: true,
    //   width: "200px",
    // },
  ];
  const exportToExcel = () => {
    // Format data for export
    const exportData = production.map((row, index) => ({
      "Sl No": index + 1,
      "Product Code": row.product_code,
      "Product Description": row.product_description,

      "Live Demand-SO": row.live_demand,
      "Stock - GOVT": row.govt_stock,
      "Stock - DLR": row.dlr_stock,
      "Stock - OTGT": row.otgt_stock,
      "Stock - Total": row.stock,
      "MTS Pending": row.total_mts,
      "Net Demand-SO": row.net_demand,
      Budget: row.total_budget,
      "Quantity to Produce(ml/gm/ea)": row.quantity_to_produce,
      Unit: row.unit,
      "Batches to Produce": row.batches_to_produce,

      // "Net Demand Value(INR)": row.demand_price,

      // GP: row.gp, // Uncomment if GP is required
    }));

    // Create a new workbook and add a worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    // Add worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Consolidated Batches-SO");

    // Trigger the download
    XLSX.writeFile(workbook, "Consolidated_Batches-SO.xlsx");
  };

  const buttonArray = [
    "Asavarishtams",
    "Thylam",
    "Lehyam",
    "Gritham",
    "Rasakriya",
    "KC",
    "KSC",
    "Choornam",
    "Liquid Kashayam",
    "Patent Items",
    "Gulika",
    "Bhasmam",
    "Siddha",
  ];
  return (
    <>
      <DefaultNavbar
        routes={page_Routes}
        action={{
          type: "external",
          route: "https://creative-tim.com/product/soft-ui-dashboard-pro-react",
          label: "buy now",
        }}
        // transparent
        dark
      />
      <SoftBox sx={{ marginTop: "65px" }} py={1}>
        <Grid container>
          <Grid md={9.5}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "40px",
                  width: "100%",
                  alignContent: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ height: "30px" }}>
                  <CustomSeparator breadcrumbs={breadcrumbs} />
                </Box>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                    marginRight: "40px",
                  }}
                >
                  <UploadFileRoundedIcon
                    sx={{
                      cursor: "pointer", // Change cursor to pointer on hover
                      color: "#1D81F6", // Default color
                      fontSize: "1.5rem !important", // Increase the size of the icon
                      transition: "0.3s",
                      mx: 3, // Smooth transition for hover effects
                      "&:hover": {
                        color: "#4DC3F8", // Change color on hover
                        fontSize: "2.5rem", // Increase icon size on hover
                      },
                    }}
                    onClick={exportToExcel}
                  />

                  <Chip
                    size="small"
                    label={`Demand  updated on: ${sales_order_last_updated}`}
                    color="success"
                    sx={{ bgcolor: "#439322", color: "white !important" }}
                  />
                  <Chip
                    size="small"
                    label={`Invoice  updated on: ${sales_invoice_last_updated}`}
                    color="success"
                    sx={{ marginLeft: "10px", bgcolor: "#439322", color: "white !important" }}
                  />
                </div>
              </div>
              <div className="ag-theme-alpine" style={{ height: "77vh", width: "94%" }}>
                <AgGridReact
                  paginationPageSize={20}
                  rowData={production}
                  columnDefs={columnDefs}
                  pagination={true}
                />
              </div>
            </div>
          </Grid>
          <Grid md={2.5}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyConntent: "center",
                alignItem: "flexEnd",
                alignContent: "flexEnd",
                gap: "10px",
                paddingInline: "50px",
              }}
              py={5}
              mb={1}
            >
              {buttonArray.map((label, index) => (
                <SoftButton
                  onClick={() => {
                    label === category ? setCategory("all") : setCategory(label);
                  }}
                  key={index}
                  variant="gradient"
                  color={label === category ? "success" : "info"}
                >
                  {label}
                </SoftButton>
              ))}
            </Box>
          </Grid>
        </Grid>
      </SoftBox>
    </>
  );
}

export default DashboardProductionActualConsolidatedSheet;
