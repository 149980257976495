/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";
import * as XLSX from "xlsx";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import SalesTable from "examples/Tables/SalesTable";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import Globe from "examples/Globe";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";
import breakpoints from "assets/theme/base/breakpoints";

// Data
import salesTableData from "layouts/dashboards/default/data/salesTableData";
import reportsBarChartData from "layouts/dashboards/default/data/reportsBarChartData";
import gradientLineChartData from "layouts/dashboards/default/data/gradientLineChartData";
import MuiTable from "examples/Tables/Mui Tables/MuiTables";
import { Box, Card, Chip, Link, Stack, Typography } from "@mui/material";
import SoftButton from "components/SoftButton";
import Paper from "@mui/material/Paper";
import SoftAlert from "components/SoftAlert";
import { Icon } from "@iconify/react";
import SoftSnackbar from "components/SoftSnackbar";
import { useEffect, useState } from "react";
import ResponsiveAppBar from "examples/Navbars/ResponsiveNavbar/ResponsiveNavbar";
import DataTable from "examples/Tables/DataTable";
import SoftSelect from "components/SoftSelect";
import DemandTable from "examples/Tables/DataTable/DemandTable";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import page_Routes from "page_routes";
import { AgGridReact } from "ag-grid-react";
import { getDemandDetailed } from "../../../redux/slices/demand";
import { dispatch, useSelector } from "../../../redux/store";
import CustomSeparator from "examples/CustomSeprator/customSeprator";
import HomeIcon from "@mui/icons-material/Home";
import axios from "axios";

function Dashboard3() {
  const { values } = breakpoints;
  const { size } = typography;
  const { chart, items } = reportsBarChartData;
  const [show, setShow] = useState(true);
  const [data, setData] = useState();
  const [category, setCategory] = useState("all");
  const [sales_order_last_updated, setsales_order_last_updated] = useState(""); // State to store demand last updated
  const [sales_invoice_last_updated, setsales_invoice_last_updated] = useState(""); // State to store invoice last updated
  const [date, setDate] = useState(null);

  useEffect(() => {
    dispatch(getDemandDetailed(category)); // Call getDemandDetailed instead of getDemandPrice
    const getDate = async () => {
      try {
        const res = await axios.get("https://staging-2.trigeminal.ai/date");
        console.log("API response:", res.data); // Log the entire response data
        const { sales_order_last_updated, sales_invoice_last_updated } = res.data;
        setsales_order_last_updated(sales_order_last_updated.date); // Update demand last updated with date
        setsales_invoice_last_updated(sales_invoice_last_updated.date);
      } catch (error) {
        console.error("Error fetching date:", error);
      }
    };

    getDate();
  }, [category]);

  const { demand } = useSelector((state) => state.demand);
  console.log(demand);

  const columnDefs = [
    {
      headerName: "Sl No",
      field: "sl_no",
      sortable: true,
      filter: true,
      width: "90px",
      pinned: "left",
    },
    {
      headerName: "Product Code",
      field: "item_code",
      sortable: true,
      filter: true,
      pinned: "left",
      width: "150px",
    },
    {
      headerName: "Item",
      field: "item_name",
      sortable: true,
      filter: true,
      pinned: "left",
      width: "200px",
    },
    {
      headerName: "Unit",
      field: "Unit",
      sortable: true,
      filter: true,
      pinned: "left",
      width: "120px",
    },
    {
      headerName: "Live Demand",
      field: "Live_Demand",
      sortable: true,
      filter: true,
      width: "140px",
    },
    { headerName: "Stock", field: "Stock", sortable: true, filter: true, width: "140px" },
    {
      headerName: "MTS pending",
      field: "mts_pending",
      sortable: true,
      filter: true,
      width: "140px",
    },
    {
      headerName: "Net Demand",
      field: "Stock_Invoice",
      sortable: true,
      filter: true,
      width: "140px",
    },

    {
      headerName: "Excess/Short",
      field: "excess_short",
      sortable: true,
      filter: true,
      width: "140px",
    },
    {
      headerName: "Excess Percentage",
      field: "excess_percentage",
      sortable: true,
      filter: true,
      width: "140px",
    },
  ];

  // const today = new Date();
  let today = new Date();
  today.setDate(today.getDate() - 1);

  for (let i = 0; i < 5; i++) {
    const date = new Date(today);
    date.setDate(today.getDate() - i);
    const formattedDate = `${date.getFullYear()}/${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}`;
    columnDefs.push({
      headerName: formattedDate,
      field: formattedDate,
      sortable: true,
      filter: true,
      width: "140px",
    });
  }

  const breadcrumbs = [
    <Link underline="hover" key="1" color="#FEFFFF" href="/dashboards/default">
      <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" color="#FEFFFF" />
    </Link>,

    <Typography sx={{ fontSize: "13px", fontWeight: 800 }} key="3" color="#FEFFFF">
      Demand Detailed{" "}
    </Typography>,
  ];
  const exportToExcel = () => {
    // Format data for export
    const today = new Date();
    const dynamicDateHeaders = [];

    // Create dynamic headers for the past 5 days
    for (let i = 1; i < 6; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      const formattedDate = `${date.getFullYear()}/${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}`;
      dynamicDateHeaders.push(formattedDate);
    }

    // Map the demand data to exportData format including the dynamic date columns
    const exportData = demand.map((row, index) => {
      let rowData = {
        "Sl No": index + 1,
        "Product Code": row.item_code,
        "Product Description": row.item_name,
        Unit: row.unit,
        "Live Demand": row.Live_Demand,
        Stock: row.Stock,
        "MTS Pending": row.mts_pending,
        "Net Demand": row.Stock_Invoice,
        "Excess/Short": row.excess_short,
        "Excess Percentage": row.excess_percentage,
      };

      // Add dynamic date fields to row data
      dynamicDateHeaders.forEach((date) => {
        rowData[date] = row[date] || 0; // Assuming you have demand data for each date
      });

      return rowData;
    });

    // Create a new workbook and add a worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    // Add worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Demand Detailed");

    // Trigger the download
    XLSX.writeFile(workbook, "demand_detailed.xlsx");
  };

  const buttonArray = [
    "Asavarishtams",
    "Thylam",
    "Lehyam",
    "Gritham",
    "Rasakriya",
    "KC",
    "KSC",
    "Choornam",
    "Liquid Kashayam",
    "Patent Items",
    "Gulika",
    "Bhasmam",
    "Siddha",
  ];

  const toggleSnackbar = () => {
    setShow(false);
  };

  return (
    <>
      <DefaultNavbar
        routes={page_Routes}
        action={{
          type: "external",
          route: "https://creative-tim.com/product/soft-ui-dashboard-pro-react",
          label: "buy now",
        }}
        // transparent
        dark
      />{" "}
      {/* 
    // <DashboardLayout>
    // <DashboardNavbar />
      // <DashboardLayout>
      // <DashboardNavbar /> */}
      <SoftBox sx={{ marginTop: "65px" }} py={1}>
        <Grid container>
          <Grid item xs={12} lg={7}>
            {/* <SoftBox mb={3} p={1}>
      <SoftTypography
        variant={window.innerWidth < values.sm ? "h3" : "h2"}
        textTransform="capitalize"
        fontWeight="bold"
      >
        general statistics
      </SoftTypography>
    </SoftBox> */}

            {/* <Grid container>
        <Grid item xs={12}>
          <Globe
            display={{ xs: "none", md: "block" }}
            position="absolute"
            top="10%"
            right={0}
            mt={{ xs: -12, lg: 1 }}
            mr={{ xs: 0, lg: 10 }}
            canvasStyle={{ marginTop: "3rem" }}
          />
        </Grid>
      </Grid> */}
          </Grid>
          {/* <Grid item lg={7}>
      <SoftAlert color="warning">
        <Icon icon="noto-v1:warning" />
        Daily demand update pending
      </SoftAlert>
    </Grid> */}
          {/* <Grid container spacing={2}>
            <Grid item xs={3}>
              <SoftBox mb={1}>
                <MiniStatisticsCard
                  title={{ text: "today's demand", fontWeight: "bold" }}
                  count="$53,000"
                  percentage={{ color: "info", text: "+55%" }}
                  icon={{ color: "info", component: "paid" }}
                />
              </SoftBox>
            </Grid>
            <Grid item xs={3}>
              <SoftBox mb={1}>
                <MiniStatisticsCard
                  title={{ text: "Demand", fontWeight: "bold" }}
                  count="2,300"
                  percentage={{ color: "success", text: "+3%" }}
                  icon={{ color: "info", component: "public" }}
                />
              </SoftBox>
            </Grid>
            <Grid item xs={3}>
              <SoftBox mb={1}>
                <MiniStatisticsCard
                  title={{ text: "Demand", fontWeight: "bold" }}
                  count="+3,462"
                  percentage={{ color: "error", text: "-2%" }}
                  icon={{ color: "info", component: "emoji_events" }}
                />
              </SoftBox>
            </Grid>
            <Grid item xs={3}>
              <SoftBox mb={1}>
                <MiniStatisticsCard
                  title={{ text: "demand", fontWeight: "bold" }}
                  count="$103,430"
                  percentage={{ color: "success", text: "+5%" }}
                  icon={{
                    color: "info",
                    component: "shopping_cart",
                  }}
                />
              </SoftBox>
            </Grid>
          </Grid> */}
        </Grid>

        {/* <Grid item xs={12} md={10} lg={7}>
          <Grid item xs={12} lg={10}>
            <SoftBox mb={3} position="relative">
              <SalesTable title="Sales by Country" rows={salesTableData} />
            </SoftBox>
          </Grid>
        </Grid> */}
        {/* <Grid container spacing={3}>
          <Grid item xs={12} lg={5}>
            <ReportsBarChart
              title="active users"
              description={
                <>
                  (<strong>+23%</strong>) than last week
                </>
              }
              chart={chart}
              items={items}
            />
          </Grid>
          <Grid item xs={12} lg={7}>
            <GradientLineChart
              title="Sales Overview"
              description={
                <SoftBox display="flex" alignItems="center">
                  <SoftBox fontSize={size.lg} color="success" mb={0.3} mr={0.5} lineHeight={0}>
                    <Icon sx={{ fontWeight: "bold" }}>arrow_upward</Icon>
                  </SoftBox>
                  <SoftTypography variant="button" color="text" fontWeight="medium">
                    4% more{" "}
                    <SoftTypography variant="button" color="text" fontWeight="regular">
                      in 2021
                    </SoftTypography>
                  </SoftTypography>
                </SoftBox>
              }
              chart={gradientLineChartData}
            />
          </Grid>
        </Grid> */}
      </SoftBox>
      {/* <Box sx={{ width: "100%", display: "flex", justifyContent: "end", marginBottom: "15px" }}>
        <Box sx={{ width: "230px", background: "white", marginRight: "7px" }}>
          <SoftSelect
            sx={{ backgroundColor: "white", height: "10px" }}
            placeholder="Select a  catagory"
            options={[
              { value: "dealer", label: "Dealer" },
              { value: "GP", label: "gp" },
              { value: "Govt", label: "govt" },
              { value: "otgt", label: "OTGT" },
              { value: "nam", label: "NAM" },
              { value: "sabharimala", label: "Sabharimala" },
              { value: "term", label: "Term" },
            ]}
          />
        </Box>
      </Box> */}
      <Grid container>
        <Grid md={9.5}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: "40px",
                width: "100%",
                alignContent: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ height: "30px" }}>
                <CustomSeparator breadcrumbs={breadcrumbs} />
              </Box>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                  marginRight: "40px",
                }}
              >
                <UploadFileRoundedIcon
                  sx={{
                    cursor: "pointer", // Change cursor to pointer on hover
                    color: "#1D81F6", // Default color
                    fontSize: "1.5rem !important", // Increase the size of the icon
                    transition: "0.3s",
                    mx: 3, // Smooth transition for hover effects
                    "&:hover": {
                      color: "#4DC3F8", // Change color on hover
                      fontSize: "2.5rem", // Increase icon size on hover
                    },
                  }}
                  onClick={exportToExcel}
                />

                <Chip
                  size="small"
                  label={`Demand  updated on: ${sales_order_last_updated}`}
                  color="success"
                  sx={{ bgcolor: "#439322", color: "white !important" }}
                />
                <Chip
                  size="small"
                  label={`Invoice  updated on: ${sales_invoice_last_updated}`}
                  color="success"
                  sx={{ marginLeft: "10px", bgcolor: "#439322", color: "white !important" }}
                />
              </div>
            </div>
            <div className="ag-theme-alpine" style={{ height: "77vh", width: "94%" }}>
              <AgGridReact
                paginationPageSize={20}
                rowData={demand}
                columnDefs={columnDefs}
                pagination={true}
              />
            </div>
          </div>
        </Grid>
        <Grid md={2.5}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyConntent: "center",
              alignItem: "flexEnd",
              alignContent: "flexEnd",
              gap: "10px",
              paddingInline: "50px",
            }}
            py={5}
            mb={1}
          >
            {buttonArray.map((label, index) => (
              <SoftButton
                onClick={() => {
                  label === category ? setCategory("all") : setCategory(label);
                }}
                key={index}
                variant="gradient"
                color={label === category ? "success" : "info"}
              >
                {label}
              </SoftButton>
            ))}
          </Box>
        </Grid>
      </Grid>
      {/* <SoftSnackbar
        color="warning"
        icon="notifications"
        title="Oushadhi"
        content="Daily demand update pending"
        // dateTime="11 mins ago"
        open={show}
        close={toggleSnackbar}
      /> */}
      {/* // <Footer />
    // </DashboardLayout> */}
    </>
  );
}

export default Dashboard3;
